import type { RouteState } from "@/__main__/router.mjs";

export default async function fetchData(
  _params: string[],
  searchParams?: URLSearchParams,
  state?: RouteState,
) {
  const apexPlatformId =
    searchParams.get("apexPlatformId") || searchParams.get("id");

  if (apexPlatformId) {
    const { default: fetchApexProfile } = await import(
      "@/game-apex/fetches/profile-page.mjs"
    );

    await fetchApexProfile([apexPlatformId, null], searchParams, {
      ...state,
      isUpdate: false,
    });
  }
}
